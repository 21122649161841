
.customMenu__afm ion-content {
  --background:#f2f2f2;
}

.customMenu__afm ion-item {
  border-bottom: 1.5px solid #e6e5e5 !important;
  --background:#f2f2f2;
  padding: 3px;
}

.customMenu__afm ion-list{
    background:#f2f2f2;
    padding-top: 20px;
}

.customMenu__afm ion-item:nth-child(1){
  margin-top: 10px;
}

.customMenu__afm .menuHeadingDiv{
  display: flex;
  padding: 0 0 3% 6%;
  border-bottom: 1.5px solid #e6e5e5
}

.customMenu__afm .menuHeadingDiv--content{
  margin-left: 5%;
}

.customMenu__afm .menuHeadingDiv--content ion-list-header{
  color: #a91d2a;
}

.customMenu__afm .menuHeadingDiv ion-icon{
    font-size: 38px !important;
}

.customMenu__afm ion-list-header{
  padding: 0 !important;
}

.logoutBtn {
  cursor: pointer;
}

.logoutBtn:hover{
--background: #e9e9e9 !important;
}

@media only screen and (min-width:1000px){
  ion-menu{
    width: 20%;
  }
}