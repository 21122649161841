.customContactUs__cl ion-card {
  box-shadow: none;
  background: #f2f2f2;
  border-radius: 20px;
  min-height: 76vh;
}

.customContactUs__cl ion-item {
  --background: none;
  --background-activated: none;
  margin-bottom: 12px;
  --min-height: 0;
}

.customContactUs__cl ion-icon.ios.hydrated {
  padding: 0;
}

.customContactUs__cl .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
   z-index: 9999999999999;
}

.customContactUs__cl .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customContactUs__cl ion-title {
  color: #fff;
}
.customContactUs__cl .profileImg__cl--wrapper{
    width: 100%;
    text-align: center;
}

.customContactUs__cl .profileImg__cl{
    width: 75%;
    margin: 0 auto;
    margin-top: 70px;
}

.customContactUs__cl ion-input {
    border: 1px solid #c12525;
    border-radius: 8px;
    --padding-start: 35px;
}

.customContactUs__cl .customContactUs__cl--label {
    position: relative;
    width: 100%;
    max-width: unset;
}

.customContactUs__cl .customContactUs__cl--label ion-icon {
    position: absolute;
    top: 9px;
    left: 3px;
    font-size: 25px !important;
}

.customContactUs__cl .customBackBtn {
  margin-bottom: 0;
}

.customContactUs__cl .profileImg__cl {
  height: 250px;
  min-height: 250px;
  max-height: 250px;
  width: 250px;
    -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transition: all 0.5s ease-in-out;
}

.customContactUs__cl a{
  width: 98%;
  color: #000 !important
}