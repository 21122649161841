.customResidentBills__afm ion-item {
  --background: #f2f2f2;
  --padding-start: 0;
  --inner-padding-end: 0;
}

.customResidentBills__afm ion-card {
  box-shadow: none;
  border-radius: 15px;
  max-height: 67vh;
  min-height: 67vh;
  overflow: auto;
  background: #f2f2f2;
}

.customResidentBills__afm h3 {
  font-size: 17px;
  color: #4c4c4c;
}

.customResidentBills__afm h6 {
  font-size: 16px;
  color: #4c4c4c;
}

.customResidentBills__afm .rb-pTag {
  display: flex;
  justify-content: flex-start;
}
.customResidentBills__afm .rb-pTag p {
  font-size: 12px;
  margin: 0;
  color: #666666;
}

.customResidentBills__afm .rb-pTag p:nth-child(1) {
  margin-right: 10px;
}

.customResidentBills__afm .view-details-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.customResidentBills__afm .view-details-right p {
  font-size: 12px;
}

.customResidentBills__afm ion-item {
  border-bottom: 1px solid #aeaeae;
}

.customResidentBills__afm .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customResidentBills__afm .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customResidentBills__afm ion-title {
  color: #fff;
}

.customNoData {
  color: gray;
  font-size: 19px;
  text-align: center;
}

.customResidentBills__afm .viewMoreBtn__fm {
  text-align: center;
  margin: 10px;
}

.customResidentBills__afm .viewMoreBtn__fm button {
  color: #fff;
  background: #c12525;
  padding: 10px;
  border-radius: 8px;
}

@media only screen and (min-width: 512px) {
  .customResidentBills__afm ion-card {
  max-height: 65vh;
  min-height: 65vh;
}
  .customResidentBills__afm .customHeading {
    padding: 0.5%;
  }
  .customResidentBills__afm .totalBills_afm {
    width: 20% !important;
    padding: 1% !important;
    margin-top: 1% !important;
    margin-bottom: 1% !important;
  }
  .customResidentBills__afm ion-card {
    width: 80%;
    margin: 0 auto;
  }
  .customResidentBills__afm .selectResident_afm {
    width: 80%;
    margin: 0 auto;
  }
  .customResidentBills__afm ion-col:nth-of-type(1){
    flex: 0 !important;
  }
    .customResidentBills__afm ion-col:nth-of-type(3){
    margin-left: 10%;
  }
.customResidentBills__afm .resident-card{
  margin-top: 1em !important;
}
}
