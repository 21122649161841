.customResidentInvoices__cl ion-item {
  --background: #f2f2f2;
  --padding-start: 0;
  --inner-padding-end: 0;
}

.customResidentInvoices__cl ion-card {
  box-shadow: none;
  border-radius: 15px;
  max-height: 87vh;
  min-height: 87vh;
  overflow: auto;
  background: #f2f2f2;
}

.customResidentInvoices__cl h3 {
  font-size: 17px;
}

.customResidentInvoices__cl h6 {
  font-size: 16px;
}

.customResidentInvoices__cl .rb-pTag {
  display: flex;
  justify-content: flex-start;
}
.customResidentInvoices__cl .rb-pTag p {
  font-size: 12px;
  margin: 0;
}

.customResidentInvoices__cl .rb-pTag p:nth-child(1) {
  margin-right: 10px;
}

.customResidentInvoices__cl .view-details-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.customResidentInvoices__cl .view-details-right p {
  font-size: 12px;
}

.customResidentInvoices__cl ion-item {
  border-bottom: 1px solid #aeaeae;
}

.customResidentInvoices__cl .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customResidentInvoices__cl .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customResidentInvoices__cl ion-title {
  color: #fff;
}

.customNoData {
  color: gray;
  font-size: 19px;
  text-align: center;
}

.customResidentInvoices__cl .viewMoreBtn__am {
  text-align: center;
  margin: 10px;
}

.customResidentInvoices__cl .viewMoreBtn__am button {
  color: #fff;
  background: #c12525;
  padding: 10px;
  border-radius: 8px;
}

@media only screen and (min-width: 512px) {
  .customResidentInvoices__cl .customHeading {
    padding: 0.5%;
  }
  .customResidentInvoices__cl ion-card {
    width: 80%;
    margin: 0 auto;
    margin-top: 1%;
  }
  .customResidentInvoices__cl ion-col:nth-of-type(1) {
    flex: 0 !important;
  }
  .customResidentInvoices__cl ion-col:nth-of-type(3) {
    margin-left: 10%;
  }
}
