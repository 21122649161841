@media only screen and (min-width: 512px){
    .auth ion-header{
        padding: 1% !important;
    }
    .auth .inputField_fp {
        width: 30%;
        margin: 0 auto;
    }
    .auth .backToLogin_fp{
        width: 10% !important;
        padding: 1% !important;
        margin-top: 1% !important;
    }
     .auth .backToLogin_fp:hover{
         cursor: pointer;
     }
     .auth .otp_fp{
         text-align: center;
         margin-top: 1%;
     }
     .changePassword ion-header{
        padding: 1% !important;
     }
     .changePassword .inputField_CP{
        width: 50%;
        margin: 0 auto;
     }
     .changePassword .submitBtn_CP{
        width: 30%;
        margin: 0 auto;
     }
     .changePassword .goBack_CP{
        margin-top: 1% !important;
        cursor: pointer;
     }

}