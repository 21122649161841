.customLogin .logoImg,
.loginBanner {
  width: 100%;
  margin: 0 auto;
  height: 130px !important;
  object-fit: contain;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transition: all 0.5s ease-in-out;
}

.customLogin ion-input{
  --padding-start: 5px !important;
}

.customLogin .loginBanner {
  height: 200px !important;
}

.customLogin .logoImg {
  margin-top: 75px;
}

.customLogin ion-input {
  border: 1.5px solid #a91d2a;
  border-radius: 5px;
  margin-bottom: 10px;
}

.customLogin .loginBtn-wrapper {
  text-align: center;
  margin-top: 25px;
}

.customLogin ion-button {
  --border-radius: 44px;
  width: 40%;
}

.customLogin form {
  padding: 20px;
}

.customLogin ion-card {
  background: none;
  box-shadow: none;
}

.customLogin .dontShowImg {
  opacity: 0;
}

.customLogin .showImg {
  opacity: 1;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@media only screen and (min-width: 512px)   {
  .customLogin .loginWrapper{
    width: 38%;
    margin: 0 auto;
  }
  .customLogin .loginBtn-wrapper{
    margin-left: 0%;
  }
  .customLogin .forgetPassword{
    padding: 0 !important;
  }
}