.customPettyCashSnapshot__fm .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customPettyCashSnapshot__fm .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customPettyCashSnapshot__fm ion-title {
  color: #fff;
}

.customPettyCashSnapshot__fm .customPettyCashSnapshot__icon {
  font-size: 25px !important;
  position: absolute;
  right: 10px;
}

.customPettyCashSnapshot__fm ion-card {
  background: none;
  box-shadow: none;
  border-radius: 0;
}

.customPettyCashSnapshot__fm ion-item {
  border: 1.5px solid #c12525;
  margin-bottom: 10px;
  border-radius: 5px;
}

.customPettyCashSnapshot__fm button {
  background: #c12525;
  color: #fff;
  padding: 14px;
  letter-spacing: 1px;
  border-radius: 41px;
  width: 111px;
}

.customPettyCashSnapshot__fm .customPettyCashSnapshot__btn {
  text-align: center;
}

.customPettyCashSnapshot__fm .customBackBtn {
  margin-bottom: 0;
}


.customPettyCashSnapshot__fm p {
  text-align: center;
}


@media screen and (min-width: 512px){
 .customPettyCashSnapshot__fm .customHeading {
    padding: 0.5%;
  }
 .customPettyCashSnapshot__fm ion-card {
    width: 50%;
    margin: 0 auto;
    margin-top: 2%;
}
}