.customQueries__cl ion-item {
  --background: #f2f2f2;
  --padding-start: 0;
  --inner-padding-end: 0;
}

.customQueries__cl ion-card {
  box-shadow: none;
  border-radius: 15px;
  max-height: 80vh;
  min-height: 80vh;
  overflow: auto;
  background: #f2f2f2;
}

.customQueries__cl h3 {
  font-size: 17px;
  color: #222;
}

.customQueries__cl .resident-card p {
  color: #717171;
  font-size: 13px;
}

.customQueries__cl h6 {
  font-size: 17px;
  color: #5d5b5b;
}
.customQueries__cl h6 span {
  color: #000;
}

.customQueries__cl .rb-pTag {
  display: flex;
  justify-content: flex-start;
}
.customQueries__cl .rb-pTag p {
  font-size: 12px;
  margin: 0;
}

.customQueries__cl .rb-pTag p:nth-child(1) {
  margin-right: 10px;
}

.customQueries__cl .view-details-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.customQueries__cl .view-details-right p {
  font-size: 12px;
}

.customQueries__cl ion-item {
  border-bottom: 1px solid #aeaeae;
}

.customQueries__cl .customHeading {
  --background: #c12525;
  padding: 4%;
}

.customQueries__cl .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customQueries__cl ion-title {
  color: #fff;
}

.customNoData {
  color: gray;
  font-size: 19px;
  text-align: center;
}

.customQueries__cl .viewMoreBtn__cl {
  text-align: center;
  margin: 10px;
}

.customQueries__cl .viewMoreBtn__cl button {
  color: #fff;
  background: #c12525;
  padding: 10px;
  border-radius: 8px;
}

@media only screen and (min-width: 512px) {
  .customQueries__cl .customHeading {
    padding: 0.5%;
  }
  .customQueries__cl ion-card {
    width: 80%;
    margin: 0 auto;
    margin-top: 2%;
}
}
