.customResidents__am ion-item {
  --background: #f2f2f2;
    --padding-start: 0;
  --inner-padding-end: 0;
}

.customResidents__am ion-card {
  box-shadow: none;
  border-radius: 15px;
  max-height: 68vh;
  min-height: 68vh;
  overflow: auto;
  background: #f2f2f2;
}

.customResidents__am h3 {
  font-size: 17px;
  color: #222;
}

.customResidents__am .resident-card p {
  color: #717171;
  font-size: 13px;
}

.customResidents__am h6 {
  font-size: 17px;
  color: #5d5b5b;
}
.customResidents__am h6:nth-of-type(1) {
 margin-right: 10px !important;
}
.customResidents__am h6 span {
  color: #000;
}

.customResidents__am .rb-pTag {
  display: flex;
  justify-content: flex-start;
}
.customResidents__am .rb-pTag p {
  font-size: 12px;
  margin: 0;
}

.customResidents__am .rb-pTag p:nth-child(1) {
  margin-right: 10px;
}

.customResidents__am .view-details-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.customResidents__am .view-details-right p {
  font-size: 12px;
}


.customResidents__am ion-item {
  border-bottom: 1px solid #aeaeae;
}


.customResidents__am .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customResidents__am .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customResidents__am ion-title {
  color: #fff;
}

.customResidents__am .viewMoreBtn__am {
  text-align: center;
  margin: 10px;
}

.customResidents__am .viewMoreBtn__am button {
  color: #fff;
  background: #c12525;
  padding: 10px;
  border-radius: 8px;
}

.customModal {
  --min-height: 150px;
}

@media only screen and (min-width: 512px) {
  .customResidents__am ion-card {
    max-height: 65vh;
    min-height: 65vh;
  }
  .customResidents__am .customHeading {
    padding: 0.5%;
  }
  .customResidents__am .totalBalance_am {
    width: 20% !important;
    padding: 1% !important;
    margin-top: 1% !important;
    margin-bottom: 1% !important;
  }
  .customResidents__am ion-card {
    width: 80%;
    margin: 0 auto;
  }
  .customResidents__am .selectHome_am {
    width: 80%;
    margin: 0 auto;
  }
    .customResidents__am .search_am {
    width: 81%;
    margin: 0 auto;
  }
}