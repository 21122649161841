.customBillDetails__afm .customBillValue {
    color: #000;
    margin-bottom: 15px;
}

.customBillDetails__afm ion-grid {
  padding: 0;
}

.customBillDetails__afm .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customBillDetails__afm .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customBillDetails__afm ion-title {
  color: #fff;
}

.customBillDetails__afm ion-card {
  box-shadow: none;
  box-shadow: 0 0 20px 0px #e6e6e6;
  border-radius: 15px;
}

.customBillDetails__afm .customImg {
  width: 100%;
  height: 44vh;
  object-fit: fill;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transition: all 0.5s ease-in-out;
}

.customBillDetails__afm .dontShowImg {
  opacity: 0;
}

.customBillDetails__afm .showImg{
  opacity: 1;
}

.customBillDetails__afm input{
  padding: 0 !important;
}

.customBillDetails__afm .amountTag{
  background: #c12525;
  color: #fff;
  padding: 2%;
  width: 50%;
  margin-left: 51%;
  border-radius: 5px;
}

@-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeIn {
   0% {opacity: 0;}
   100% {opacity: 1;}
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@media only screen and (min-width: 512px){

.customBillDetails__afm .customHeading {
  padding: 0.5%;
}
.customBillDetails__afm ion-card {
    width: 54%;
    margin: 0 auto;
    padding: 1%;
    margin-top: 1%;
}
.customModal1 {
    --min-height: 200px !important;
}
}