.customTrackExpenses__mgmt ion-item {
  --background: #f2f2f2;
  --padding-start: 0;
  --inner-padding-end: 0;
}

.customTrackExpenses__mgmt ion-card {
  box-shadow: none;
  border-radius: 15px;
  max-height: 85vh;
  min-height: 85vh;
  overflow: auto;
  background: #f2f2f2;
}

.customTrackExpenses__mgmt h3 {
  font-size: 17px;
  color: #222;
}

.customTrackExpenses__mgmt .resident-card p {
  color: #717171;
  font-size: 13px;
}

.customTrackExpenses__mgmt h6 {
  font-size: 17px;
  color: #5d5b5b;
}
.customTrackExpenses__mgmt h6 span {
  color: #000;
}

.customTrackExpenses__mgmt .rb-pTag {
  display: flex;
  width: 245px;
  justify-content: flex-start;
}
.customTrackExpenses__mgmt .rb-pTag p {
  font-size: 12px;
  margin: 0;
}

.customTrackExpenses__mgmt .rb-pTag p:nth-child(1) {
  margin-right: 10px;
}

.customTrackExpenses__mgmt .view-details-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 4.5rem;
}

.customTrackExpenses__mgmt .view-details-right p {
  font-size: 12px;
}

.customTrackExpenses__mgmt .resident-card {
}

.customTrackExpenses__mgmt ion-item {
  border-bottom: 1px solid #aeaeae;
}

.customTrackExpenses__mgmt .customResidentView {
  margin-left: 47px;
  margin-top: 17px;
}

.customTrackExpenses__mgmt .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customTrackExpenses__mgmt .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customTrackExpenses__mgmt ion-title {
  color: #fff;
}

.customTrackExpenses__mgmt .viewMoreBtn__am {
  text-align: center;
  margin: 10px;
}

.customTrackExpenses__mgmt .viewMoreBtn__am button {
  color: #fff;
  background: #c12525;
  padding: 10px;
  border-radius: 8px;
}

.customModal {
  --min-height: 150px;
}

@media only screen and (min-width: 512px) {
  .customTrackExpenses__mgmt .customHeading {
    padding: 0.5%;
  }
  .customTrackExpenses__mgmt ion-card {
    width: 80%;
    margin: 0 auto;
    margin-top: 1%;
}
  .customTrackExpenses__mgmt  ion-col:nth-of-type(1) {
    flex: 0 !important;
  }
  .customTrackExpenses__mgmt  ion-col:nth-of-type(3) {
    margin-left: 28%;
  }
  .customTrackExpenses__mgmt .selectOption-mgmt{
    width: 80%;
    margin: 0 auto;
  }
}
