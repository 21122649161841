.customRejectedBills__fm ion-item {
  --background: #f2f2f2;
      --padding-start: 0;
  --inner-padding-end: 0;
}

.customRejectedBills__fm ion-card {
  box-shadow: none;
  border-radius: 15px;
  max-height: 85vh !important;
  min-height: 85vh !important;
  overflow: auto;
  background: #f2f2f2;
}

.customRejectedBills__fm h3 {
  font-size: 17px;
  color: #222;
}

.customRejectedBills__fm .resident-card p {
  font-size: 13px;
  color: #717171;
}

.customRejectedBills__fm h6 {
  font-size: 17px;
  color: #5d5b5b;
}
.customRejectedBills__fm h6 span {
  color: #000;
}

.customRejectedBills__fm .rb-pTag {
  display: flex;
  justify-content: flex-start;
}
.customRejectedBills__fm .rb-pTag p {
  font-size: 12px;
  margin: 0;
}

.customRejectedBills__fm .rb-pTag p:nth-child(1) {
  margin-right: 10px;
}

.customRejectedBills__fm .view-details-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.customRejectedBills__fm .view-details-right p {
  font-size: 12px;
}

.customRejectedBills__fm ion-item {
  border-bottom: 1px solid #aeaeae;
}


.customRejectedBills__fm .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customRejectedBills__fm .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customRejectedBills__fm ion-title {
  color: #fff;
}

.customRejectedBills__fm .viewMoreBtn__fm {
  text-align: center;
  margin: 10px;
}

.customRejectedBills__fm .viewMoreBtn__fm button {
  color: #fff;
  background: #c12525;
  padding: 10px;
  border-radius: 8px;
}

p#tpc {
  margin: 20px 40px;
  font-size: 16px !important;
  text-align: center;
  background: #c12525;
  padding: 15px 10px;
  border-radius: 8px;
  color: #fff;
}

@media only screen and (min-width: 512px) {
  .customRejectedBills__fm ion-card {
    max-height: 77vh;
    min-height: 77vh;
  }
  .customRejectedBills__fm .customHeading {
    padding: 0.5%;
  }
  .customRejectedBills__fm .totalBills_fm {
    width: 20% !important;
    padding: 1% !important;
    margin-top: 1% !important;
    margin-bottom: 1% !important;
  }
  .customRejectedBills__fm ion-card {
    width: 80%;
    margin: 0 auto;
    margin-top: 2%;
  }
  .customRejectedBills__fm .selectHome_fm {
    width: 80%;
    margin: 0 auto;
    margin-top: 1%;
  }
  .customRejectedBills__fm ion-col:nth-of-type(1) {
    flex: 0 !important;
  }
  .customRejectedBills__fm ion-col:nth-of-type(3) {
    margin-left: 2%;
  }
}