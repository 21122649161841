.customTopUpHistory__am ion-item {
  --background: #f2f2f2;
}

.customTopUpHistory__am ion-card {
  box-shadow: none;
  border-radius: 15px;
  max-height: 86vh !important;
  min-height: 86vh !important;
  margin-top: 0;
  overflow: auto;
  background: #f2f2f2;
}

.customTopUpHistory__am h3 {
  font-size: 17px;
  min-width: 221px;
}

.customTopUpHistory__am h6 {
  font-size: 16px;
}

.customTopUpHistory__am .rb-pTag {
  display: flex;
  width: 245px;
  justify-content: flex-start;
}
.customTopUpHistory__am .rb-pTag p {
  font-size: 12px;
  margin: 0;
}

.customTopUpHistory__am .rb-pTag p:nth-child(1) {
  margin-right: 10px;
}

.customTopUpHistory__am .view-details-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 4.5rem;
}

.customTopUpHistory__am .view-details-right p {
  font-size: 12px;
}

.customTopUpHistory__am ion-item {
  border-bottom: 1px solid #aeaeae;
}

.customTopUpHistory__am .customResidentView {
  margin-left: 47px;
  margin-top: 12px;
}

.customTopUpHistory__am .customHeading {
  --background: #c12525;
  padding: 4%;
    position: sticky;
  top: 0;
   z-index: 9999999999999;
}

.customTopUpHistory__am .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customTopUpHistory__am ion-title {
  color: #fff;
}

.customTopUpHistory__am .customPetty__totBalance {
  font-size: 14px;
  text-align: left;
  color: #c12525;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
}


.customTopUpHistory__am .viewMoreBtn__fm {
   text-align: center;
   margin: 10px;
}

.customTopUpHistory__am .viewMoreBtn__fm button {
    color: #fff;
    background:#c12525;
    padding: 10px;
    border-radius: 8px;
}

@media only screen and (min-width: 512px) {
  .customTopUpHistory__am ion-card {
    max-height: 65vh;
    min-height: 65vh;
  }
  .customTopUpHistory__am .customHeading {
    padding: 0.5%;
  }
  .customTopUpHistory__am ion-card {
    width: 80%;
    margin: 0 auto;
    margin-top: 1.5%;
  }
  .customTopUpHistory__am .selectHome_am {
    width: 80%;
    margin: 0 auto;
  }
  .customTopUpHistory__am ion-col:nth-of-type(1) {
    flex: 0 !important;
  }
}