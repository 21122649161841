.customBillAction__am .customBillValue {
  color: #000;
  margin-bottom: 10px;
}

.customBillAction__am .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customBillAction__am .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customBillAction__am ion-title {
  color: #fff;
}

.customBillAction__am .customApproveBtn--am,
.customRejectBtn--am {
  text-align: center;
  padding: 11px;
  border-radius: 26px;
  background: #56c156;
    padding: 4%;
    width: 100%;
    color: #fff;
}

.customBillAction__am .customApproveBtn--am button,
.customRejectBtn--am button {
  color: #ffffff;
}

.customBillAction__am .customApproveBtn--am {
  background: #56c156;
}

.customBillAction__am .customRejectBtn--am {
  background: #a91d2a;
}

.customBillAction__card {
  box-shadow: none;
  box-shadow: 0 0 20px 0px #e6e6e6;
  border-radius: 15px;
}

.customBillAction__am .customImg {
  width: 100%;
  height: 44vh;
  object-fit: fill;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transition: all 0.5s ease-in-out;
}

.customBillAction__am .bill-details-right {
  margin-top: 0;
}

.customModal {
  --height: 13%;
  --width: 64%;
}

.modalButton1 {
  --background: #56c156;
}

.modalButton2 {
  --background: #c12525;
}

.customBillAction__am ion-grid {
  padding: 0;
}

.customBillAction__am .dontShowImg {
  opacity: 0;
}

.modalInput {
  --color: #000 !important;
}

.customBillAction__am .showImg {
  opacity: 1;
}

.customBillAction__am .amountTag{
  background: #c12525;
  color: #fff;
  padding: 2%;
  width: 50%;
  margin-left: 51%;
  border-radius: 5px;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@media only screen and (min-width: 512px) {
  .customBillAction__am .customBillAction__card {
    width: 54%;
    margin: 0 auto;
    margin-top: 1%;
    margin-bottom: 1%;
    padding: 1%;
  }
  .customBillAction__am .customHeading {
    padding: 0.5%;
  }
}
