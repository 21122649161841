.customProfile__afm ion-card {
  box-shadow: none;
  background: #f2f2f2;
  border-radius: 20px;
  min-height: 87vh;
}

.customProfile__afm ion-item {
  --background: none;
  --background-activated: none;
  margin-bottom: 12px;
  --min-height: 0;
}

.customProfile__afm ion-icon.ios.hydrated {
  padding: 0;
}

.customProfile__afm .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customProfile__afm .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customProfile__afm ion-title {
  color: #fff;
}
.customProfile__afm .profileImg__afm--wrapper {
  width: 100%;
}

.customProfile__afm .profileImg__afm {
  width: 75%;
  margin: 0 auto;
  margin-top: 70px;
}

.customProfile__afm ion-input {
  border: 1px solid #c12525;
  border-radius: 8px;
  --padding-start: 35px;
}

.customProfile__afm .customProfile__afm--label {
  position: relative;
  width: 100%;
  max-width: unset;
}

.customProfile__afm .customProfile__afm--label ion-icon {
  position: absolute;
  top: 9px;
  left: 3px;
  font-size: 25px !important;
}

.customProfile__afm .customBackBtn {
  margin-bottom: 0;
}

.customProfile__afm .profileImg__afm {
  height: 300px;
  min-height: 300px;
  max-height: 300px;
  width: 100%;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transition: all 0.5s ease-in-out;
}

.customProfile__afm .dontShowImg {
  opacity: 0;
}

.customProfile__afm a {
  width: 100%;
  margin: 10px 0 10px 0;
}

.customProfile__afm .showImg {
  opacity: 1;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@media only screen and (min-width: 512px) {
  .customProfile__afm ion-card {
    width: 80%;
    margin: 0 auto;
    margin-top: 1%;
  }
  .customProfile__afm .customHeading {
  padding: 0.5%;
}
}
