.customResidentDetails__am ion-item {
  --background: #f2f2f2;
}

.customResidentDetails__am ion-card {
  box-shadow: none;
  border-radius: 15px;
  max-height: 85vh;
  min-height: 85vh;
  overflow: auto;
  background: #f2f2f2;
}

.customResidentDetails__am h3 {
  font-size: 12px;
  min-width: 228px;
}

.customResidentDetails__am h6 {
  font-size: 17px;
}

.customResidentDetails__am .rb-pTag {
  display: flex;
  width: 245px;
  justify-content: flex-start;
}
.customResidentDetails__am .rb-pTag p {
  font-size: 12px;
  margin: 0;
}

.customResidentDetails__am .rb-pTag p:nth-child(1) {
  margin-right: 10px;
}

.customResidentDetails__am .view-details-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 4.5rem;
}

.customResidentDetails__am .view-details-right p {
  font-size: 12px;
}

.customResidentDetails__am ion-item {
  border-bottom: 1px solid #aeaeae;
}

.customResidentDetails__am .customResidentView {
  margin-left: 47px;
  margin-top: 12px;
}

.customResidentDetails__am .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customResidentDetails__am .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customResidentDetails__am ion-title {
  color: #fff;
}

.customResidentDetails__am .viewMoreBtn__am {
  text-align: center;
  margin: 10px;
}

.customResidentDetails__am .viewMoreBtn__am button {
  color: #fff;
  background: #c12525;
  padding: 10px;
  border-radius: 8px;
}

@media only screen and (min-width: 512px) {
  .customResidentDetails__am ion-card {
    max-height: 84vh;
    min-height: 84vh;
  }
  .customResidentDetails__am .customHeading {
    padding: 0.5%;
  }
  .customResidentDetails__am .totalBalance_wrapper {
    width: 30%;
    margin: 0 auto !important;
  }
  .customResidentDetails__am .totalBills_am {
    width: 50% !important;
    padding: 1.5% !important;
    margin-top: 1.5% !important;
    margin-bottom: 1.5% !important;
    margin-left: 1%;
  }
  .customResidentDetails__am ion-card {
    width: 80%;
    margin: 0 auto;
  }
  .customResidentDetails__am .selectHome_am {
    width: 80%;
    margin: 0 auto;
  }
  .customResidentDetails__am ion-col:nth-of-type(2) {
    margin-left: 15%;
  }
  .customResidentDetails__am .view-details-right{
      width: unset;
  }
}
