.customResidentBills__fm--exp ion-item {
  --background: #f2f2f2;
    --padding-start: 0;
  --inner-padding-end: 0;
}

.customResidentBills__fm--exp ion-card {
  box-shadow: none;
  border-radius: 15px;
  max-height: 77vh !important;
  min-height: 77vh !important;
  margin-top: 0;
  overflow: auto;
  background: #f2f2f2;
}

.customResidentBills__fm--exp h3 {
  font-size: 13px;
  margin-bottom: 2px !important;
}

.customResidentBills__fm--exp h6 {
  font-size: 14px;
  margin-top: 1px !important;
}

.customResidentBills__fm--exp .rb-pTag {
  display: flex;
  justify-content: flex-start;
}
.customResidentBills__fm--exp .rb-pTag p {
  font-size: 10px;
}

.customResidentBills__fm--exp .rb-pTag p:nth-child(1) {
  margin-right: 10px;
}

.customResidentBills__fm--exp .view-details-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.customResidentBills__fm--exp .view-details-right p {
  font-size: 12px;
}

.customResidentBills__fm--exp ion-item {
  border-bottom: 1px solid #aeaeae;
}

/* .customResidentBills__fm--exp .customResidentView {
  margin-left: 47px;
  margin-top: 12px;
} */

.customResidentBills__fm--exp .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customResidentBills__fm--exp .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customResidentBills__fm--exp ion-title {
  color: #fff;
}

.customResidentBills__fm--exp .customPetty__totBalance {
  font-size: 14px;
  text-align: left;
  color: #c12525;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
}

.customResidentBills__fm--exp .customPetty__totBalance p {
  color: #fff;
  background: #c12525;
  font-size: 13px;
  padding: 2%;
  border-radius: 5px;
}

.customResidentBills__fm--exp .viewMoreBtn__fm {
  text-align: center;
  margin: 10px;
}

.customResidentBills__fm--exp .viewMoreBtn__fm button {
  color: #fff;
  background: #c12525;
  padding: 10px;
  border-radius: 8px;
}

@media only screen and (min-width: 512px) {
  .customResidentBills__fm--exp ion-card {
    max-height: 69vh !important;
    min-height: 69vh !important;
  }
  .customResidentBills__fm--exp .customHeading {
    padding: 0.5%;
  }
  .customResidentBills__fm--exp .totalBills_fm {
    width: 20% !important;
    padding: 1% !important;
    margin-top: 1% !important;
    margin-bottom: 1% !important;
  }
  .customResidentBills__fm--exp ion-card {
    width: 80%;
    margin: 0 auto;
    margin-top: 2%;
  }
   .customResidentBills__fm--exp .totalBalance_wrapper{
    width: 80%;
    margin: 0 auto !important;
    margin-top: 2% !important;
  }

  .customResidentBills__fm--exp ion-col:nth-of-type(1) {
    flex: 0 !important;
  }
  .customResidentBills__fm--exp ion-col:nth-of-type(3) {
    margin-left: 2%;
  }
}