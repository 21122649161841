.customPettyCash__cl ion-item {
  --background: #f2f2f2;
  --padding-start: 0;
  --inner-padding-end: 0;
}

.customPettyCash__cl ion-card {
  box-shadow: none;
  border-radius: 15px;
  max-height: 81vh;
  min-height: 81vh;
  overflow: auto;
  background: #f2f2f2;
}

.customPettyCash__cl h3 {
  font-size: 17px;
}

.customPettyCash__cl h6 {
  font-size: 16px;
}

.customPettyCash__cl .rb-pTag {
  display: flex;
  justify-content: flex-start;
}
.customPettyCash__cl .rb-pTag p {
  font-size: 12px;
  margin: 0;
}

.customPettyCash__cl .rb-pTag p:nth-child(1) {
  margin-right: 10px;
}

.customPettyCash__cl .view-details-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.customPettyCash__cl .view-details-right p {
  font-size: 12px;
}



.customPettyCash__cl ion-item {
  border-bottom: 1px solid #aeaeae;
}


.customPettyCash__cl .customHeading {
  --background: #c12525;
  padding: 4%;
}

.customPettyCash__cl .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customPettyCash__cl ion-title {
  color: #fff;
}

.customPetty__totBalance {
  font-size: 15px;
  text-align: center;
  color: #fff;
  background: #c12525;
  padding: 2%;
  width: 50%;
  margin: 0 auto;
  margin-top: 2%;
  border-radius: 18px;
}

.customPettyCash__cl .viewMoreBtn__fm {
  text-align: center;
  margin: 10px;
}

.customPettyCash__cl .viewMoreBtn__fm button {
  color: #fff;
  background: #c12525;
  padding: 10px;
  border-radius: 8px;
}

@media only screen and (min-width: 512px) {
  .customPettyCash__cl ion-card {
    max-height: 80vh;
    min-height: 80vh;
    margin-top: 1vh;
  }
  .customPettyCash__cl .customHeading {
    padding: 0.5%;
  }
  .customPettyCash__cl .totalBalance_cl {
    width: 20% !important;
    padding: 1% !important;
    margin-top: 1% !important;
    margin-bottom: 1% !important;
  }
  .customPettyCash__cl ion-card {
    width: 80%;
    margin: 0 auto;
  }
  .customPettyCash__cl ion-col:nth-of-type(1) {
    flex: 0 !important;
  }
  .customPettyCash__cl ion-col:nth-of-type(3) {
    margin-left: 10%;
  }
}
