.customRaiseInvoice__am ion-item {
  --background: #f2f2f2;
  --padding-start: 0;
  --inner-padding-end: 0;
}

.customRaiseInvoice__am ion-card {
  box-shadow: none;
  border-radius: 15px;
  max-height: 74vh;
  min-height: 74vh;
  overflow: auto;
  background: #f2f2f2;
}

.customRaiseInvoice__am h3 {
  font-size: 17px;
  color: #222;
}

.customRaiseInvoice__am .resident-card p {
  color: #717171;
  font-size: 13px;
}

.customRaiseInvoice__am h6 {
  font-size: 17px;
  color: #5d5b5b;
}
.customRaiseInvoice__am h6 span {
  color: #000;
}

.customRaiseInvoice__am .rb-pTag {
  display: flex;
  justify-content: flex-start;
}
.customRaiseInvoice__am .rb-pTag p {
  font-size: 12px;
  margin: 0;
}

.customRaiseInvoice__am .rb-pTag p:nth-child(1) {
  margin-right: 10px;
}

.customRaiseInvoice__am .view-details-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.customRaiseInvoice__am .view-details-right p {
  font-size: 12px;
}

.customRaiseInvoice__am ion-item {
  border-bottom: 1px solid #aeaeae;
}

.customRaiseInvoice__am .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customRaiseInvoice__am .generateInvoice{
  background: #c12525;
  color: #fff;
  padding: 2%;
  margin: 0 auto;
  border-radius: 5px;
  margin-top: 1%;
    margin-bottom: 1%;
}

.customRaiseInvoice__am .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customRaiseInvoice__am ion-title {
  color: #fff;
}

.customRaiseInvoice__am .viewMoreBtn__am {
  text-align: center;
  margin: 10px;
}

.customRaiseInvoice__am .viewMoreBtn__am button {
  color: #fff;
  background: #c12525;
  padding: 10px;
  border-radius: 8px;
}

.customModal {
  --min-height: 150px;
}

@media only screen and (min-width: 512px) {
  .customRaiseInvoice__am .customHeading {
    padding: 0.5%;
  }
  .customRaiseInvoice__am ion-card {
    width: 80%;
    margin: 0 auto;
    margin-top: 1%;
  }
  .customRaiseInvoice__am .selectItem_am {
    width: 80%;
    margin: 0 auto;
    margin-top: 1%;
  }
  .customRaiseInvoice__am ion-card {
    max-height: 70vh;
    min-height: 70vh;
  }
}
