.customTechSupport ion-card {
  box-shadow: none;
  background: #f2f2f2;
  border-radius: 20px;
  min-height: 85vh;
  padding: 4%;
}

.customTechSupport ion-card p{
 font-size: 1.2em;
 text-align: left;
}

.customTechSupport ion-card a{
 color: #c12525 !important;
 text-decoration: underline;
 font-style: italic;
}

.customTechSupport ion-item {
  --background: none;
  --background-activated: none;
  margin-bottom: 12px;
  --min-height: 0;
  /* margin-top: 20px; */
}

.customTechSupport ion-icon.ios.hydrated {
  padding: 0;
}

.customTechSupport .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customTechSupport .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customTechSupport ion-title {
  color: #fff;
}
.customTechSupport .profileImg__cl--wrapper {
  width: 100%;
  text-align: center;
}

.customTechSupport .profileImg__cl {
  width: 75%;
  margin: 0 auto;
  margin-top: 70px;
}

.customTechSupport ion-input {
  border: 1px solid #c12525;
  border-radius: 8px;
  --padding-start: 35px;
}

.customTechSupport .customTechSupport--label {
  position: relative;
  width: 100%;
  max-width: unset;
}

.customTechSupport .customTechSupport--label ion-icon {
  position: absolute;
  top: 9px;
  left: 3px;
  font-size: 25px !important;
}

.customTechSupport .customBackBtn {
  margin-bottom: 0;
}

.customTechSupport .profileImg__cl {
  height: 250px;
  min-height: 250px;
  max-height: 250px;
  width: 250px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transition: all 0.5s ease-in-out;
}

.customTechSupport a {
  width: 98%;
  color: #000 !important;
}

@media only screen and (min-width: 512px) {
  .customTechSupport .customHeading {
    padding: 0.5%;
  }
  .customTechSupport ion-card {
    width: 80%;
    margin: 0 auto;
    margin-top: 2%;
}
}
