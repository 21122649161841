.customAbout__cl .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
  color: #fff;
}
.customAbout__cl .row1 {
  background: #c12525;
  display: flex;
  padding: 4%;
  padding-right: 0;
  padding-bottom: 0;
}
.customAbout__cl .row1 .row11 {
  width: 40%;
}

.customAbout__cl .row1 .row12 {
  flex: 1;
  width: 60%;
}
.customAbout__cl .row1 .row12 p {
  color: #fff;
  font-size: 1.2em;
  letter-spacing: 2px;
  font-weight: 300;
  margin-bottom: 0.2em;
  margin-left: 6%;
}
.customAbout__cl .row1 .row12 p:nth-of-type(2) {
  background: #fff;
  color: #c12525;
  margin-left: 0;
  padding-left: 6%;
}

.customAbout__cl .row2 {
  padding: 3%;
  padding-left: 6%;
  padding-right: 6%;
}

.customAbout__cl .row2 p {
  color: #333;
  text-align: justify;
  margin-bottom: 1em;
}

@media only screen and (min-width: 512px) {
  .customAbout__cl .customHeading {
    padding: 0.5%;
  }
  .customAbout__cl .row1 .row11 {
    width: 23%;
  }
  .customAbout__cl .row1 {
    height: unset;
    padding-left: 26%;
  }
  .customAbout__cl .mainImage_cl {
    width: 50%;
    margin: 0 auto;
    margin-top: 2%;
  }
  .customAbout__cl .row2 {
    width: 58%;
    margin: 0 auto;
  }
}
