.customRaiseQuery__cl .customBillValue {
    color: #000;
    margin-bottom: 15px;
}

.customRaiseQuery__cl ion-grid {
  padding: 0;
}

.customRaiseQuery__cl .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customRaiseQuery__cl .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customRaiseQuery__cl ion-title {
  color: #fff;
}

.customRaiseQuery__cl ion-card {
  box-shadow: none;
  box-shadow: 0 0 20px 0px #e6e6e6;
  border-radius: 15px;
}

.customRaiseQuery__cl .customImg {
  width: 100%;
  height: 44vh;
  object-fit: fill;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transition: all 0.5s ease-in-out;
}

.customRaiseQuery__cl .raiseQueryBtn

.customRaiseQuery__cl .dontShowImg {
  opacity: 0;
}

.customRaiseQuery__cl .showImg{
  opacity: 1;
}

@-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeIn {
   0% {opacity: 0;}
   100% {opacity: 1;}
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@media only screen and (min-width: 512px){
  .customRaiseQuery__cl .customHeading {
   padding: 0.5%;
}
.customRaiseQuery__cl ion-card {
    width: 50%;
    margin: 0 auto;
    margin-top: 2%;
    padding: 1%;
}
}