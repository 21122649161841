.customHome__afm ion-card {
  box-shadow: none;
  background: #f2f2f2;
  border-radius: 20px;
}

.customHome__afm ion-item {
  --background: none;
  --background-activated: none;
  margin-bottom: 12px;
  --min-height: 0;
}

.customHome__afm ion-icon.ios.hydrated {
  padding: 0;
}

.customHome__afm .image-box {
  width: 50px;
  border: solid #cbcbcb;
  border-width: 4px;
  padding: 1px;
  border-radius: 50%;
  height: 50px;
  margin-right: 2vw;
}
.customHome__afm .image-box .image-container {
  width: 40px;
  border: solid #c12525;
  border-width: 2px;
  border-radius: 50%;
  height: 40px;
}

.customHome__afm ion-icon {
  font-size: 26px !important;
  margin-top: 5px;
  margin-left: 5px;
}

.customHome__afm .customQueryCard {
  min-height: 34vh !important;
  max-height: 34vh !important;
  border-radius: 20px;
  box-shadow: 0 0 20px -7px #dfdfdf;
  background: none;
}

.customHome__afm .customQueryWrapper {
  overflow: auto;
  height: 30vh;
}
.customHome__afm .customQueryCard ion-card-title {
  text-align: center;
  font-size: 19px;
  letter-spacing: 1.5px;
}

.customHome__afm .customQueryCard .customQuery span {
  color: #c12525;
  margin-left: 5px;
}

.customHome__afm .customQueryCard .customQuery {
  font-size: 17px;
  border-radius: 14px;
  color: #6b6b6b;
  box-shadow: 0 0 20px -7px white;
  padding: 10px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 11px;
  background: #f2f2f2;
  background-repeat: no-repeat;
  background-position: left top;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.customHome__afm .customQueryCard .customQuery div {
  text-align: center;
}

.customHome__afm .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customHome__afm .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customHome__afm ion-title {
  color: #fff;
}

@media only screen and (min-width: 512px) {
  .customHome__afm .customHeading {
    padding: 0.5%;
  }
  .customHome__afm ion-card {
    width: 80%;
    margin: 0 auto;
    margin-top: 2%;
  }
}
