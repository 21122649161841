.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}

.ios-safeArea .customHeading {
  padding-top: 26px !important;
}

.landingScreens .iosLandingScreen {
  margin-top: 9vh !important;
}

.ios-safeArea ion-menu ion-list {
  padding-top: 8vh;
}

body {
  overflow: hidden; /* Hide scrollbars */
}
