.customFacilityInvoices__am ion-item {
  --background: #f2f2f2;
  --padding-start: 0;
  --inner-padding-end: 0;
}

.customFacilityInvoices__am ion-card {
  box-shadow: none;
  border-radius: 15px;
  max-height: 68vh;
  min-height: 68vh;
  overflow: auto;
  background: #f2f2f2;
}

.customFacilityInvoices__am h3 {
  font-size: 16px;
  color: #4C4C4C;
}

.customFacilityInvoices__am h6 {
  font-size: 15px;
  color: #4C4C4C;
}

.customFacilityInvoices__am .rb-pTag {
  display: flex;
  justify-content: flex-start;
}
.customFacilityInvoices__am .rb-pTag p {
  font-size: 10.5px;
  margin: 0;
  color: #666666;
}

.customFacilityInvoices__am .rb-pTag p:nth-child(1) {
  margin-right: 7px;
}

.customFacilityInvoices__am .view-details-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.customFacilityInvoices__am .view-details-right p {
  font-size: 12px;
}

.customFacilityInvoices__am ion-item {
  border-bottom: 1px solid #aeaeae;
}

.customFacilityInvoices__am .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customFacilityInvoices__am .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customFacilityInvoices__am ion-title {
  color: #fff;
}

.customNoData {
  color: gray;
  font-size: 19px;
  text-align: center;
}

.customFacilityInvoices__am .viewMoreBtn__am {
  text-align: center;
  margin: 10px;
}

.customFacilityInvoices__am .viewMoreBtn__am button {
  color: #fff;
  background: #c12525;
  padding: 10px;
  border-radius: 8px;
}

@media only screen and (min-width: 512px) {
  .customFacilityInvoices__am ion-card {
    max-height: 65vh;
    min-height: 65vh;
  }
  .customFacilityInvoices__am .customHeading {
    padding: 0.5%;
  }
  .customFacilityInvoices__am .totalBills_am {
    width: 20% !important;
    padding: 1% !important;
    margin-top: 1% !important;
    margin-bottom: 1% !important;
  }
  .customFacilityInvoices__am ion-card {
    width: 80%;
    margin: 0 auto;
  }
  .customFacilityInvoices__am .selectHome_am {
    width: 80%;
    margin: 0 auto;
  }
  .customFacilityInvoices__am ion-col:nth-of-type(1) {
    flex: 0 !important;
  }
  .customFacilityInvoices__am ion-col:nth-of-type(3) {
    margin-left: 2%;
  }
}