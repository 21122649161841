.spinLoader {
  --color: #c12525;
  padding: 21px;
  position: absolute;
  left: 43%;
}

.customLoader{
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .customLoader {
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    top: 0 !important;
    margin: 0;
  }
}
