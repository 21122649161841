@media only screen and (min-width: 512px) {
  .landingScreens .logoImg {
    width: 15% !important;
    margin-bottom: 1% !important;
  }
  .landingScreens ion-card {
    margin-top: 1% !important;
  }
  .landingScreens ion-slides {
    width: 80%;
  }
  .landingScreens .landingImg4 {
    width: 46% !important;
    margin: 0 auto;
  }
  .landingScreens .ctaIconRow {
    width: 36%;
    margin: 0 auto !important;
    margin-top: 2% !important;
  }

  .landingScreens .btnRow {
    margin: 0 !important;
    margin-top: 3% !important;
  }
  .landingScreens .landingScreenLogin {
    padding: 1% !important;
    width: 18% !important;
  }
  .landingScreens .splashScreens_mobile {
    display: none !important;
  }
}
