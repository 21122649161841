.customBillUpload__afm ion-card {
  box-shadow: none;
  background: #f2f2f2;
  border-radius: 20px;
  height: 87vh;
  overflow: auto;
  margin-top: 0;
}

.customBillUpload__afm ion-item {
  --background: none;
  --background-activated: none;
  /* margin-bottom: 12px;
  --min-height: 0; */
}

.customBillUpload__afm ion-item {
  margin-bottom: 10px;
}

.customBillUpload__afm ion-label {
  color: #333 !important;
  font-weight: 600 !important;
  margin-bottom: 12px !important;
}

/* .customBillUpload__afm .label-stacked.sc-ion-label-ios-h {
  font-weight: 600;
  margin-bottom : 12px;
} */

/* .customBillUpload__afm ion-select,
.customBillUpload__afm ion-input,
.customBillUpload__afm ion-textarea,
.customBillUpload__afm ion-datetime {
  border: 1.5px solid #a91d2a;
  border-radius: 6px;
  min-height: 20px;
  width: 98%;
} */

.customBillUpload__afm .customHeading {
  --background: #c12525;
  padding: 14px;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customBillUpload__afm .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customBillUpload__afm ion-title {
  color: #fff;
}

/* .customBillUpload__afm ion-icon {
  color: #fff;
} */

.customBillUpload__afm .uploadPicture {
  border: 1.5px solid #a91d2a;
  border-radius: 6px;
  /* min-height: 43px; */
  width: 91%;
  margin: 0 auto;
  /* height: 43px;
  margin-left: 18px;
  height: 3.2rem; */
}

.customBillUpload__afm .customInputFields {
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0px;
  --padding-start: 0;
  padding: 3% !important;
  border: 1.5px solid #a91d2a;
  border-radius: 6px;
}

@media only screen and (min-width: 512px) {
  .customBillUpload__afm .customHeading {
    padding: 0.5%;
  }
  .customBillUpload__afm ion-card {
    width: 80%;
    margin: 0 auto;
  }
  .customBillUpload__afm .customInputFields {
    padding: 1% !important;
  }
  .customBillUpload__afm .uploadPicture {
    width: 97%;
  }
  .customBillUpload__afm .totalImages_afm {
    width: 16% !important;
    margin-top: 0 !important;
    margin-left: 1.5% !important;
  }
}

.sc-ion-alert-md-h {
  --min-width: 325px;
}