.customQueryDetails__am .customBillValue {
    color: #000;
    margin-bottom: 15px;
}

.customQueryDetails__am ion-grid {
  padding: 0;
}

.customQueryDetails__am .customHeading {
  --background: #c12525;
  padding: 4%;
  position: sticky;
  top: 0;
  z-index: 9999999999999;
}

.customQueryDetails__am .customHeading ion-buttons ion-menu-button {
  color: #fff !important;
}

.customQueryDetails__am ion-title {
  color: #fff;
}

.customQueryDetails__am ion-card {
  box-shadow: none;
  box-shadow: 0 0 20px 0px #e6e6e6;
  border-radius: 15px;
}

.customQueryDetails__am .customImg {
  width: 100%;
  height: 44vh;
  object-fit: fill;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transition: all 0.5s ease-in-out;
}

.customQueryDetails__am h6#resident-card-display-in-line {
    border-right: none;
}
.customQueryDetails__am .raiseQueryBtn

.customQueryDetails__am .dontShowImg {
  opacity: 0;
}

.customQueryDetails__am .showImg{
  opacity: 1;
}

@-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeIn {
   0% {opacity: 0;}
   100% {opacity: 1;}
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@media only screen and (min-width: 512px) {
  .customQueryDetails__am ion-card {
    width: 54%;
    margin: 0 auto;
    margin-top: 1%;
    margin-bottom: 1%;
    padding: 1%;
  }
  .customQueryDetails__am  .customHeading {
    padding: 0.5%;
  }
}