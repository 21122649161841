/* ion-title.ios.title-default.hydrated {
  font-size: 22px !important;
}
ion-menu.ios ion-item ion-icon {
  padding: 0 !important;
}
ion-icon.ios.hydrated {
  font-size: 25px;
  padding: 10px 10px 10px 45px;
  color: #a91d2a;
}
ion-menu-button.ios.button.in-toolbar.ion-activatable.ion-focusable.hydrated, ion-label.sc-ion-label-ios-h.sc-ion-label-ios-s.ios.hydrated, ion-back-button.ios.button.in-toolbar.ion-activatable.ion-focusable.show-back-button.hydrated {
    color: #A91D2A;
}

ion-menu.ios ion-item {
  border-bottom: 1px solid #aaa;
}
ion-menu.ios ion-content {
  width: 270px;
  background: #ccc;
}
.button-native{
  background: #A91D2A;
}

label, a{
    text-decoration: none;
    color: #A91D2A !important;
}

h3, h6 {
    margin: 5px 0 !important;
}
h3{
    color: #333;
}
h6 {
    font-size: 15px !important;
}

.hr{
    margin: 10px 5px;
    border: 1px solid #eee;
}

.resident-card {
  width: 100%;
  display: inline-grid;
}

.view-details-right{
    margin-top: 12px;
    padding-left: 45px;
}

.resident-card p {
  color: #999;
  font-size: 14px;
  margin: 0;
}

button#billdetail-balance, button.submitBill, button#billaction {
    padding: 10px;
    background: #A91D2A;
    border-radius: 5px;
    color: #fff;
    margin: 0 auto;
}
button#billaction {
  margin: 0;
}

.bill-details-right {
  margin-top: 18px;
  padding-left: 45px;
}
.resident-card.display-in-line h6 {
    margin-right: 10px;
}
.resident-card.display-in-line {
    display: flex;
}
h6#resident-card-display-in-line{
   padding-right: 15px !important;
    border-right: 1px solid #999;
    margin-right: 15px !important;
}
 */

 ion-title.ios.title-default.hydrated {
  font-size: 22px !important;
}
ion-menu.ios ion-item ion-icon {
  padding: 0 !important;
}
/* ion-icon.ios.hydrated {
  font-size: 25px;
  padding: 10px 10px 10px 45px;
  color: #a91d2a;
} */
 ion-icon.ios.hydrated {
  font-size: 35px;
  color: #a91d2a;
}
/* ion-menu-button.ios.button.in-toolbar.ion-activatable.ion-focusable.hydrated, ion-label.sc-ion-label-ios-h.sc-ion-label-ios-s.ios.hydrated, ion-back-button.ios.button.in-toolbar.ion-activatable.ion-focusable.show-back-button.hydrated {
    color: #A91D2A;
} */

ion-back-button{
  color: #fff;
}

ion-icon{
  color: #A91D2A;
}
/* ion-menu.ios ion-item {
  border-bottom: 1px solid #aaa;
} */
/* ion-menu.ios ion-content {
  width: 270px;
  background: #ccc;
} */
.button-native{
  background: #A91D2A;
}

p{
  margin: 0;
}

label, a{
    text-decoration: none;
    color: #A91D2A !important;
}

h3, h6 {
    margin: 5px 0 !important;
}
h3{
    color: #333;
}
/* h6 {
    font-size: 15px !important;
} */

/* .hr{
    margin: 10px 5px;
    border: 1px solid #eee;
} */
/*
.resident-card {
  width: 100%;
  display: inline-grid;
} */

/* .view-details-right{
    margin-top: 12px;
    padding-left: 45px;
} */

/* .resident-card p {
  color: #999;
  font-size: 14px;
  margin: 0;
} */

button#billdetail-balance, button.submitBill {
    padding: 10px;
    background: #A91D2A;
    border-radius: 5px;
    color: #fff;
    margin: 0 auto;
}

.bill-details-right {
  margin-top: 18px;
  padding-left: 45px;
}
.resident-card.display-in-line h6 {
    margin-right: 10px;
}
.resident-card.display-in-line {
    display: flex;
}
h6#resident-card-display-in-line{
   padding-right: 15px !important;
    border-right: 1px solid #999;
    margin-right: 15px !important;
}

button {
  background: none;
  box-shadow: none;
  border: none;
}